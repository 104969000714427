var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-selectable-title', {
    attrs: {
      "options": [{
        label: 'Sign in',
        value: 'signIn'
      }, {
        label: 'Live demo',
        value: 'liveDemo'
      }]
    },
    model: {
      value: _vm.formType,
      callback: function callback($$v) {
        _vm.formType = $$v;
      },
      expression: "formType"
    }
  }), _vm.formType === 'signIn' ? _c('login-form') : _vm._e(), _vm.formType === 'liveDemo' ? _c('live-demo-form') : _vm._e()], 1)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }