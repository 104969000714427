





















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import LoginForm from "@/modules/auth/components/LoginForm.vue";
import LiveDemoForm from "@/modules/auth/components/LiveDemoForm.vue";
import SygniSelectableTitle from "@/components/layout/SygniSelectableTitle.vue";

@Component({
  components: {
    LiveDemoForm, SygniSelectableTitle,
    LoginForm, SygniContainerTitle, SygniCheckbox, SygniLine, SygniRadio, SygniRoundedButton, SygniInput}
})
export default class Login extends Vue {

  formType: 'signIn' | 'liveDemo' = 'signIn';

  mounted(){
    if (['signIn', 'liveDemo'].includes((this.$route.query.type) as string)) {
      this.formType = this.$route.query.type as 'signIn' | 'liveDemo';
    }
  }
}

